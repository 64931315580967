import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {
    Box,  Card,CardMedia, Hidden,
    //TextField, FormControl, Tooltip, LinearProgress,
    //Select, MenuItem, InputLabel,  Typography, 
} from '@material-ui/core'
import injectFbSDKScript from './sdk-fb'
import Head from './Header'
import ModalInformativo from '../generales/ModalInformativo';
import 'react-toastify/dist/ReactToastify.css';
const useStyles = makeStyles({
    root: {
      maxWidth: 360,
      height: 400,
    },
    media: {
      height: 400,
      width: 350
    },
    rootMV: {
        maxWidth: 280,
        height: 280,
      },
    mediaMV: {
        height: 275,
        width: 270
    },
  });
const ModalCompartirFB = ({ modalAbierto, setModalAbierto, titulo, perfilCompartir }) => {
    const classes = useStyles();
    const imageURL = perfilCompartir.url;
    const title = 'Yo Soy Nuevo Espacio'
    const description = ''
    const setCardDivRef = el => {
        injectFbSDKScript();
    }

    const DatosGenerales = (
        <Box display={"flex"} justifyContent="center" flexDirection={"column"}>
            <Head
                title={title}
                description={description}
                imageUrl={"https://yosoynuevoespacio.daduva.mx"}
            />
            <Box display={"flex"} justifyContent="center">
                
                <Hidden smUp>
                <Card className={classes.rootMV}>
                    <CardMedia className={classes.mediaMV}
                    image={imageURL}
                    title="Yo Soy Nuevo Espacio"
                /> </Card>
                </Hidden>
                <Hidden xsDown>
                <Card className={classes.root}>
                    <CardMedia className={classes.media}
                    image={imageURL}
                    title="Yo Soy Nuevo Espacio"
                /> </Card>
                </Hidden>
            </Box>
            <Box display={"flex"} justifyContent="center" mt={1}>
           <div  ref={setCardDivRef}
           >
                <div className="fb-share-button" data-href={imageURL} data-layout="button"
                    data-size="large" 
                >
                    <a target="_blank" className="fb-xfbml-parse-ignore"
                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                    >
                        Compartir
                    </a>
                </div>          
           </div>
           </Box>
        </Box>
    )

    return (
    <>
        <ModalInformativo
            titulo={titulo} subtitulo={''} modalAbierto={modalAbierto}
            setModalAbierto={setModalAbierto} tamanio={'sm'}
            colorFondo={'primary'}
        >
            {DatosGenerales}
        </ModalInformativo>
    </>
    )
}

export default ModalCompartirFB