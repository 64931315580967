import {useRef} from "react";
import {Grid, Typography,CardMedia,Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Principal from "./Principal";
import VideoJS from "../generales/VideoJS";
import ContactoRedes from "../generales/ContactoRedes";

const useStyles = makeStyles((theme) => ({
    "@global": {
        body: {
            minWidth: '100vw', minHeight: '100vh',
        },
    }, texto: {
        textShadow: '2px 2px #f73378', color: "#ffffff", margin: '2rem 0 2rem 0'
    }, textoFooter: {
        color: "#ffffff", fontSize: 1.5 + "em",textTransform:"uppercase",
    }, item1: {
        order: 3, [theme.breakpoints.up("sm")]: {
            order: 1
        }, margin: '2rem 0 2rem 0'
    }, item2: {
        order: 1, [theme.breakpoints.up("sm")]: {
            order: 2
        }, margin: '2rem 0 2rem 0'
    }, item3: {
        order: 2, [theme.breakpoints.up("sm")]: {
            order: 3
        }
    }, item4: {
        color: 'white', order: 4, [theme.breakpoints.up("sm")]: {
            order: 4
        },
    }
}));

const VentanaWeb = () => {
    const classes = useStyles();
    const playerRef = useRef(null);
    const date = new Date()
    let year = date.getFullYear()

    const videoJsOptions = {
        autoplay: true, controls: true, responsive: true, fluid: true, sources: [{
            src: 'https://social-whatsapp.daduva.mx/publico/MC2022.mp4', type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        player.on('waiting', () => {
            player.log('player is waiting');
        });

        player.on('dispose', () => {
            player.log('player will dispose');
        });
    };

    return (<Grid container alignItems={'center'} justifyContent={'center'}
                  style={{backgroundColor: '#aa1976', minWidth: '100vw', minHeight: '100vh', textAlign: 'center'}}>
        <Grid item xs={12} md={4} className={classes.item1}>
            <div
                className="fb-page"
                data-href="https://www.facebook.com/NuevoEspacioValleDeChalco"
                data-tabs="timeline"
                data-width=""
                data-height=""
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
            >
                <blockquote
                    cite="https://www.facebook.com/NuevoEspacioValleDeChalco"
                    className="fb-xfbml-parse-ignore"
                >
                    <a href="https://www.facebook.com/NuevoEspacioValleDeChalco">Nuevo Espacio</a>
                </blockquote>
            </div>
        </Grid>
        <Grid item xs={12} md={4} className={classes.item2}>
            <Grid container alignItems={'center'} justifyContent={'center'} style={{textAlign: 'center'}}>
                <Grid item xs={12} className={classes.texto}>
                    <Typography variant="h3">
                        BIENVENIDOS
                    </Typography>
                </Grid>
                <Grid item xs={11}>
                    {/* <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/> */}
                    <Box display="flex" justifyContent="center">
                    <CardMedia style={{marginBottom:1+"rem",width:"15rem"}}
                        component="img" alt="Logo NuevoEspacio"                    
                        image="NuevoEspacio.svg" title="Logo NuevoEspacio"
                    />
                    </Box>
                    
                </Grid>
                <Grid item xs={12}>
                    <ContactoRedes/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.textoFooter} >
                        Juntos nada nos detiene
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={4} className={classes.item3}>
            <Principal/>
        </Grid>
        <Grid item xs={12} className={classes.item4}>
            <hr></hr>
            <Typography variant="subtitle2">
                © {year}
            </Typography>
        </Grid>
    </Grid>)
};

export default VentanaWeb;
