import {createTheme} from '@material-ui/core/styles'

let colorBarra = "#aa1976";
let colorBarraHover = "#db6eb4";
let colorBackBarra = "#f2dcea";

export function generarTema(colorTema) {

    const Tema = createTheme({
        palette: {
            type: (colorTema ? 'dark' : 'light'), primary: {
                light: '#aa1976', main: '#bb4791', dark: '#761152', contrastText: '#fff'
            }, secondary: {
                light: '#f50057', main: '#f73378', dark: '#ab003c', contrastText: '#fff'
            }, success: {
                light: '#049b4f', main: '#06DE72', dark: '#37e48e', contrastText: ' rgba(0, 0, 0, 0.87)'
            }, error: {
                light: "#e57373", main: "#f44336", dark: "#d32f2f", contrastText: "#fff"
            },
        }, overrides: {
            MuiCssBaseline: {
                "@global": {
                    body: {
                        scrollbarColor: colorBarra + " " + colorBarraHover,
                        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                            backgroundColor: colorBackBarra,
                        },
                        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                            borderRadius: 8,
                            backgroundColor: colorBarra,
                            minHeight: 24,
                            border: "3px solid " + colorBarra,
                        },
                        "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                            backgroundColor: colorBackBarra,
                        },
                        "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                            backgroundColor: colorBackBarra,
                        },
                        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: colorBarraHover,
                        },
                        "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                            backgroundColor: colorBackBarra,
                        },
                    },
                },
            },
        }
    })

    return Tema
}