import {useEffect, useState} from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AuthProvider from './componentes/context/AuthContext';
import GeneralesProvider from './componentes/context/GeneralesContext';
import Login from './componentes/auth/Login'
import {generarTema} from "./componentes/layout/Tema";
import {ToastContainer} from "react-toastify";
import Contenedor from "./componentes/layout/Contenedor";
import PantallaFb from './componentes/VentanasConStepper/PantallaFB'

function App() {
    const [colorTema, setColorTema] = useState(false);

    useEffect(() => {
        const auxColor = localStorage.getItem("ColorTheme");
        if (auxColor === undefined || auxColor === null) {
            localStorage.setItem("ColorTheme", colorTema);
        } else {
            setColorTema(JSON.parse(auxColor));
        }
    }, []);

    return (
     
    <AuthProvider>
    <GeneralesProvider>
        <Router>
            <ThemeProvider theme={generarTema(colorTema)}>
                <Contenedor colorTema={colorTema} setColorTema={setColorTema}>
                    <Switch>
                        <Route exact path="/" component={Login}/>
                        <Route exact path="/entrada" component={PantallaFb}/>
                        <Route path="*" component={Login}/>
                    </Switch>
                </Contenedor>
                <ToastContainer autoClose={5000} closeOnClick/>
            </ThemeProvider>
        </Router>
    </GeneralesProvider>
    </AuthProvider>
    
    );
}

export default App;
