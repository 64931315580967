import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'; //This dependency injects the META labels code in the head of the HTML

const Head = ({ title, description, imageUrl }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={ imageUrl} />
                <meta property='og:image:width' content='350' />
                <meta property='og:image:height' content='350' />
                <meta property="og:url" content={"https://yosoynuevoespacio.mx"} />
                <meta property="og:type" content="website" />
            </Helmet>
        </HelmetProvider>
    )
}

export default Head