import React from "react";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        color: "white", width: 50, height: 50, '&:hover': {
            color: '#f73378'
        }
    }, margin: {
        margin: '2rem 0 2rem 0',
    }
}));

const BotonesContacto = () => {

    const classes = useStyles();

    return (<Grid container alignItems={'center'} justifyContent={'center'}>
        <Grid item xs={2} className={classes.margin}>
            <Tooltip title={'Facebook'}>
                <IconButton>
                    <FacebookIcon className={classes.button}/>
                </IconButton>
            </Tooltip>
        </Grid>
       {/*  <Grid item xs={2} className={classes.margin}>
            <Tooltip title={'Instagram'}>
                <IconButton>
                    <InstagramIcon className={classes.button}/>
                </IconButton>
            </Tooltip>
        </Grid>
        <Grid item xs={2} className={classes.margin}>
            <Tooltip title={'Twitter'}>
                <IconButton>
                    <TwitterIcon className={classes.button}/>
                </IconButton>
            </Tooltip>
        </Grid> */}
    </Grid>);
};
export default BotonesContacto