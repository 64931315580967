import {authUser} from '../funciones/AuthUser'
import {AuthContext} from '../context/AuthContext'
import {useHistory} from "react-router-dom";
import {GeneralesContext} from '../context/GeneralesContext'
import {useContext, useEffect, useState} from "react";
import VentanaWebFB from "./VentanaWebFB";
import injectFbSDKScript from "../fb-share/sdk-fb";

const Pantalla = () => {
    let history = useHistory();
    const {usuario, guardarUsuario} = useContext(AuthContext);
    const {generales, guardarGenerales} = useContext(GeneralesContext);
    const [bandera, setBandera] = useState(false)

    useEffect(() => {
        const usu = localStorage.getItem('UsuarioNom');
        const nvl = localStorage.getItem('Tipo');
        let auxValidacion = authUser();
        if (!auxValidacion) {
            guardarUsuario([])
            guardarGenerales([])
            localStorage.clear();
            history.push("/")
        } else {
            if (usuario.length === 0) {
                guardarUsuario({Nombre: usu, nivel: nvl});
            }
            setBandera(true)
        }
    }, [])

    return (<>
        {bandera ? <>
            <div id="fb-root"></div>
            {injectFbSDKScript()}
            <VentanaWebFB/>
        </> : null}
    </>);
};

export default Pantalla;
